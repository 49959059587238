import React, { Component } from "react";
import "../../styles/framework.min.scss";
import styles from "../../styles/home.module.scss";

class Intro extends Component {
  render() {

    let showResume = false;
    let extLink = showResume ? "https://drive.google.com/file/d/1TlR1aAoxNHf6VcVkMQQvhuMlMYDcMplP/view" : "https://linkedin.com/in/jasper-gan"

    return (
      <div className={styles.intro}>
        <div className="container">
          <div className="row">
            <div className="col-sm-9 offset-sm-2 col-12">
              <div className={styles.header}>
                <h2 className={styles.heading}>Hi, I'm Jasper</h2>
              </div>
              <div className={styles.blurb}>
                <p className={styles.text}>
                  Currently a student at UC Berkeley studying Computer
                  Science. Previously at PayPal and Snackpass.
                </p>
                <br />
                <p className={styles.text}>
                  Passionate about full-stack dev and startups. Feel
                  free to check out my{" "}
                  <a
                    className={styles.link}
                    href={extLink}
                    target="_blank"
                  >
                    resume
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
