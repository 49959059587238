import React, { Component } from "react";
import bearmaps from "../../img/bearmaps.png";
import audience1st from "../../img/audience1st.png";
import hashdocs from "../../img/hashdocs.png";
import stocksense from "../../img/stocksense.png";
import tapcrate from "../../img/tapcrate.png";
import theflightpost from "../../img/theflightpost.png";
import "../../styles/framework.min.scss";
import styles from "../../styles/home.module.scss";

class Project extends Component {
  render() {
    const { showModal } = this.props;
    return (
      <div className={styles.project}>
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-12">
              <h3 className={styles.label}>PROJECT</h3>
            </div>
            <div className="col-sm-10 col-12">
              <div className="row">
                <div className="col-sm-4 col-6">
                  <div className={styles.group} onClick={() => { return showModal('tapcrate') }}>
                    <img className={styles.img} src={tapcrate} />
                    <h2 className={styles.title}>Tapcrate</h2>
                    <h4 className={styles.subtitle}>Marketplace</h4>
                  </div>
                </div>
                <div className="col-sm-4 col-6">
                  <div className={styles.group} onClick={() => { return showModal('tfp') }}>
                    <img className={styles.img} src={theflightpost} />
                    <h2 className={styles.title}>TheFlightPost</h2>
                    <h4 className={styles.subtitle}>Social Media</h4>
                  </div>
                </div>
                <div className="col-sm-4 col-6">
                  <div className={styles.group} onClick={() => { return showModal('hashdocs') }}>
                    <img className={styles.img} src={hashdocs} />
                    <h2 className={styles.title}>Hashdocs</h2>
                    <h4 className={styles.subtitle}>Cloud Storage</h4>
                  </div>
                </div>
                <div className="col-sm-4 col-6">
                  <div className={styles.group} onClick={() => { return showModal('stocksense') }}>
                    <img className={styles.img} src={stocksense} />
                    <h2 className={styles.title}>Stocksense</h2>
                    <h4 className={styles.subtitle}>Financial Tech</h4>
                  </div>
                </div>
                <div className="col-sm-4 col-6" onClick={() => { return showModal('bearmaps') }}>
                  <div className={styles.group}>
                    <img className={styles.img} src={bearmaps} />
                    <h2 className={styles.title}>BearMaps</h2>
                    <h4 className={styles.subtitle}>Utility</h4>
                  </div>
                </div>
                <div className="col-sm-4 col-6" onClick={() => { return showModal('audience1st') }}>
                  <div className={styles.group}>
                    <img className={styles.img} src={audience1st} />
                    <h2 className={styles.title}>Audience1st</h2>
                    <h4 className={styles.subtitle}>E-Commerce</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Project;
