import React, { Component } from "react";
import paypal from "../../img/paypal.png";
import snackpass from "../../img/snackpass.png";
import "../../styles/framework.min.scss";
import styles from "../../styles/home.module.scss";

class Experience extends Component {
  render() {
    return (
      <div className={styles.work}>
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-12">
              <h3 className={styles.label}>WORK</h3>
            </div>
            <div className="col-sm-10 col-12">
              <div className={styles.inner}>
                <div className={styles.header}>
                  <div className={styles.heading}>
                    <h2>PayPal</h2>
                    <i>
                      <a href="https://www.paypal.com" target="_blank">
                        <svg viewBox="0 0 512 512">
                          <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z" />
                        </svg>
                      </a>
                    </i>
                  </div>
                  <h4 className={styles.subheading}>
                    Software Engineering Intern - Summer 2019
                  </h4>
                </div>
                <div className={styles.logo}>
                  <a href="https://www.paypal.com" target="_blank">
                    <img
                      className={styles.img}
                      src={paypal}
                    />
                  </a>
                </div>
              </div>
              <div className={styles.inner}>
                <div className={styles.header}>
                  <div className={styles.heading}>
                    <h2>Snackpass</h2>
                    <i>
                      <a href="https://www.snackpass.com" target="_blank">
                        <svg viewBox="0 0 512 512">
                          <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z" />
                        </svg>
                      </a>
                    </i>
                  </div>
                  <h4 className={styles.subheading}>
                    Engineering/Product Intern - Spring 2019
                  </h4>
                </div>
                <div className={styles.logo}>
                  <a href="https://www.snackpass.com" target="_blank">
                    <img
                      className={styles.img}
                      src={snackpass}
                    />
                  </a>
                </div>
              </div>
              <div className={styles.inner}>
                <div className={styles.header}>
                  <div className={styles.heading}>
                    <h2>PayPal</h2>
                    <i>
                      <a href="https://www.paypal.com" target="_blank">
                        <svg viewBox="0 0 512 512">
                          <path d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm113.9 231L234.4 103.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L285.1 256 183.5 357.6c-9.4 9.4-9.4 24.6 0 33.9l17 17c9.4 9.4 24.6 9.4 33.9 0L369.9 273c9.4-9.4 9.4-24.6 0-34z" />
                        </svg>
                      </a>
                    </i>
                  </div>
                  <h4 className={styles.subheading}>
                    Software Engineering Intern - Summer 2018
                  </h4>
                </div>
                <div className={styles.logo}>
                  <a href="https://www.paypal.com" target="_blank">
                    <img
                      className={styles.img}
                      src={paypal}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Experience;
