import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Home";

class Root extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/resume" component={Home} />
          <Route component={Home} />
        </Switch>
      </Router>
    );
  }
}

export default Root;
