import React, { Component } from "react";
import "../../styles/framework.min.scss";
import styles from "../../styles/home.module.scss";

class School extends Component {
  render() {
    return (
      <div className={styles.school}>
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-12">
              <h3 className={styles.label}>SCHOOL</h3>
            </div>
            <div className="col-sm-10 col-12">
              <div className={styles.inner}>
                <div className={styles.header}>
                  <h1 className={styles.heading}>UC Berkeley 🎓</h1>
                  <h4 className={styles.subheading}>
                    B.A. Computer Science
                  </h4>
                </div>
                <div className={styles.date}>2016 - 2020</div>
              </div>
              <div className={styles.inner}>
                <div className={styles.header}>
                  <h1 className={styles.heading}>Leland High School 🎓</h1>
                  <h4 className={styles.subheading}>
                    Valedictorian
                  </h4>
                </div>
                <div className={styles.date}>2012 - 2016</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default School;
