import React, { Component } from 'react';
import Content from "../components/Home";

class Home extends Component {
  render() {
    return (
      <div>
        <Content />
      </div>
    );
  }
}

export default Home;
