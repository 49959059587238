import React, { Component } from "react";
import img from "../../img/theflightpost.png";
import "../../styles/framework.min.scss";
import styles from "../../styles/home.module.scss";

class TFP extends Component {
  render() {
    const { hideModal } = this.props;
    return (
      <div className={styles.inner}>
        <div className={styles.header}>
          <span className={styles.heading}>TheFlightPost</span>
          <div className={styles.dismiss}>
            <button className={styles.btn} onClick={hideModal}>
              <i>
                <svg viewBox="0 0 352 512">
                  <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                </svg>
              </i>
            </button>
          </div>
        </div>
        <div className={styles.body}>
          <img
            className={styles.image}
            src={img}
          />
          <p className={styles.blurb}>
            I built an open platform for reading and writing aviation
            trip reports entirely from scratch using a React frontend
            and a Node + MongoDB backend.
          </p>
          <a className={styles.link} href="https://theflightpost.com" target="_blank">Visit Website</a>
        </div>
      </div>
    );
  }
}

export default TFP;
