import React, { Component } from "react";
import Intro from "./Intro";
import Work from "./Work";
import School from "./School";
import Project from "./Project";
import Stack from "./Stack";
import Contact from "./Contact";
import Footer from "./Footer";
import Modal from "../Modal";
import "../../styles/framework.min.scss";
import styles from "../../styles/home.module.scss";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      type: null
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  showModal(type) {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100vh";
    document.body.style.boxSizing = "border-box";
    this.setState({
      modalIsOpen: true,
      modalType: type
    });
  }
  hideModal() {
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("height");
    document.body.style.removeProperty("box-sizing");
    this.setState({
      modalIsOpen: false
    });
  }
  render() {
    const { modalType, modalIsOpen } = this.state;
    return (
      <div className={styles.home}>
        <Intro />
        <Work />
        <School />
        {/**<Project showModal={this.showModal} />**/}
        <Stack />
        <Contact />
        <Modal type={modalType} modalIsOpen={modalIsOpen} hideModal={this.hideModal} />
      </div>
    );
  }
}

export default Content;
