import React, { Component } from "react";
import "../../styles/framework.min.scss";
import styles from "../../styles/home.module.scss";

class Stack extends Component {
  render() {
    return (
      <div className={styles.stack}>
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-12">
              <h3 className={styles.label}>STACK</h3>
            </div>
            <div className="col-sm-10 col-12">
              <div className={styles.values}>
                <h4 className={styles.value}>
                  <a className={styles.link} href="https://reactjs.org" target="_blank">React</a>
                </h4>
                <h4 className={styles.value}>
                  <a className={styles.link} href="https://nodejs.org" target="_blank">Node</a>
                </h4>
                <h4 className={styles.value}>
                  <a className={styles.link} href="https://www.python.org" target="_blank">Python</a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stack;
