import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import BearMaps from "./BearMaps";
import Audience1st from "./Audience1st";
import Hashdocs from "./Hashdocs";
import Stocksense from "./Stocksense";
import Tapcrate from "./Tapcrate";
import TFP from "./TFP";
import "../../styles/framework.min.scss";
import styles from "../../styles/home.module.scss";

class Modal extends Component {
  render() {
    const { type, modalIsOpen, hideModal } = this.props;
    let component;
    switch(type) {
      case "tapcrate":
        component = <Tapcrate hideModal={hideModal} />;
        break;
      case "tfp":
        component = <TFP hideModal={hideModal} />;
        break;
      case "hashdocs":
        component = <Hashdocs hideModal={hideModal} />;
        break;
      case "stocksense":
        component = <Stocksense hideModal={hideModal} />;
        break;
      case "bearmaps":
        component = <BearMaps hideModal={hideModal} />;
        break;
      case "audience1st":
        component = <Audience1st hideModal={hideModal} />;
        break;
    }
    if (modalIsOpen) {
      return (
        <TransitionGroup>
          <CSSTransition
            classNames={{
              enter: styles.enter,
              exit: styles.exit,
              enterActive: styles.enterActive,
              exitActive: styles.exitActive
            }}
            timeout={{ enter: 200, exit: 200 }}
          >
            <div data-modal="true" className={styles.modal}>
              <div data-modal="true" className={styles.wrapper}>
                {component}
              </div>
            </div>
          </CSSTransition>
        </TransitionGroup>
      );
    }
    return <TransitionGroup timeout={{ enter: 200, exit: 200 }} />;
  }
}

export default Modal;
