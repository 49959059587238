import React, { Component } from "react";
import "../../styles/framework.min.scss";
import styles from "../../styles/home.module.scss";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmail: false
    };
    this.toggleEmail = this.toggleEmail.bind(this);
  }
  toggleEmail() {
    this.setState({
      showEmail: !this.state.showEmail
    });
  }
  render() {
    const { showEmail } = this.state;
    return (
      <div className={styles.contact}>
        <div className="container">
          <div className="row">
            <div className="col-sm-2 col-12">
              <h3 className={styles.label}>TALK</h3>
            </div>
            <div className="col-sm-9 col-12">
              <div className={styles.blurb}>
                <h4 className={styles.call}>Let's get in touch!</h4>
                <p className={styles.text}>
                  I love meeting new people and am always down for a nice coffee
                  chat. Here's how to reach me:
                </p>
              </div>
              <div className={styles.values}>
                <h4 className={styles.value}>
                  {showEmail ? (
                    <a className={styles.link} href="mailto:jasper.gan@berkeley.edu">jasper.gan@berkeley.edu</a>
                  ):(
                    <span className={styles.link} onClick={this.toggleEmail}>Email</span>
                  )}
                </h4>
                <h4 className={styles.value}>
                  <a className={styles.link} href="https://linkedin.com/in/jasper-gan" target="_blank">LinkedIn</a>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
